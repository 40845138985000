import { projectProductService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ProjectProductDetail, SaveProjectProduct } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Prop } from 'vue-property-decorator';
import { PrepressStatusEnum, ProjectItemTypeEnum } from '@/resource/enum';
import ProjectProductForm from '../project-product-form/project-product-form.vue';

@Component({
  components: { ProjectProductForm }
})
export default class AddProjectProduct extends mixins(DialogMixin) {
  /**
   * 项次id，当复制项次时，该属性有值
   */
  @Prop({
    type: Number,
    required: false,
    default: 0
  })
  public itemId!: number;

  @InjectReactive()
  public readonly projectCode!: string;

  @InjectReactive()
  public readonly projectId!: number;

  @InjectReactive()
  public readonly customerId!: number;

  public projectProductForm: Partial<SaveProjectProduct> = {
    itemType: ProjectItemTypeEnum.finishedProduct,
    backendFlag: 0,
    printingFlag: 0,
    prepressFlag: 0,
    platformPrepressFlag: 1,
    prepressDesignFlag: 1,
    prepressFinishFlag: 1,
    logisticsFlag: 0,
    confirmFlag: 0,
    installFlag: 0,
    prepressCrafts: undefined,
    finishHeight: undefined,
    finishWidth: undefined,
    visibleHeight: undefined,
    visibleWidth: undefined,
    backendCrafts: '',

    count: undefined,
    pointName: '',
    platformProductId: undefined,
    platformProductName: '',
    customerProductName: '',
    prepressPicture: '',
    prepressRemark: '',
    remark: '',
    requiredDeliveryTime: '',
    requiredArriveTime: '',
    prepressStatus: PrepressStatusEnum.new,
    prepressSubmitCount: 1,

    areaPriceFlag: 1,
    priceUnit: 0,
    priceCount: 1,
    salePrice: 0,
    priceIncludingTax: 0,
    taxRate: 0,
    totalPrice: 0,
    taxAmount: 0,
    amountIncludingTax: 0,
    
    pocketFlag: 0,
    topPocketSize: 0,
    bottomPocketSize: 0
  };

  public loaded = false;

  public title = 'project.addItem';

  public created(): void {
    if (this.itemId) {
      this.getDetails(this.itemId);
      return;
    }
    this.loaded = true;
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }

  public async onSubmit(): Promise<void> {
    try {
      await (this.$refs.projectProductForm as ProjectProductForm).validateForm();
    } catch {
      Message.error(translation('common.submitTip'));
    }
    try {
      const validateRes = await (this.$refs.projectProductForm as ProjectProductForm).validateForm();
      if (!validateRes) {
        return;
      }
      this.setLoading(true);
      this.projectProductForm.projectId = this.projectId;
      this.projectProductForm.projectCode = this.projectCode;
      await projectProductService.post(this.projectProductForm as SaveProjectProduct);

      Message.success(translation('operationRes.addSuccess'));
      this.$emit('add-success');
      this.closeDialog();
    } catch (error) {
      if (error) messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  private getDetails(id: number): void {
    projectProductService
      .getById(id)
      .then(res => {
        Object.assign(this.projectProductForm, res);
        this.projectProductForm.prepressStatus = PrepressStatusEnum.new;
        this.loaded = true;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 该项次是否需要生产
   * @param projectItemData
   * @returns
   */
  private isNeedProduction(projectItemData: Partial<SaveProjectProduct> | ProjectProductDetail): boolean {
    return (
      projectItemData.itemType !== ProjectItemTypeEnum.changeColor &&
      (projectItemData.prepressFlag === 1 || projectItemData.backendFlag === 1 || projectItemData.printingFlag === 1)
    );
  }

  /**
   * 该项次是否需要印前
   * @param projectItemData
   * @returns
   */
  private isNeedPrepress(projectItemData: Partial<SaveProjectProduct> | ProjectProductDetail): boolean {
    return projectItemData.platformPrepressFlag === 1;
  }
}
